const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://www.refactoracademy.com",
		"gaTrackingId": "UA-66796502-4"
	},
	"header": {
		"logo": '',
		"title": "Refactor Academy",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
	},
	"sidebar": {
		"forcedNavOrder": [
			"/first-javascript-code",
			"/javascript",
			"/course-overview",
			"/tools",
			"/intro-to-code",
			"/variables-and-data-types",
			"/properties-and-methods",
			"/strings",
			"/numbers-and-math",
			"/booleans",
			"/control-flow",
			"/loops",
			"/functions",
			"/arrays",
			"/objects",
			"/function-in-an-object",
		],
		"links": [
			{ "text": "", "link": ""},
		]
	},
	"siteMetadata": {
		"title": "Refactor Academy | Code Courses",
		"description": "Learn web development",
		"ogImage": null,
		"docsLocation": "https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content",
		"favicon": "https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg"
	},
};

module.exports = config;